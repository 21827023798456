import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form, 
  Modal,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";



import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
const Config = require('config');
var md5 = require('md5');

class Profile extends Component {
  constructor(props) {
    super(props);
   
    this.vForm = this.refs.vForm;
    this.state = { showModal: false,
      error:false,
      loading: true,
      alert: null,
      show: false,
      username:'',
      firstname:"",
      lastname:"",
      old_passwordError:null,
      new_passwordError:null,
      new_password2Error:null,
      old_password:"",
      new_password:"",
      new_password2:"",
      update:false

    
     
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit= this.handleSubmit.bind(this);
this.handleValidate = this.handleValidate.bind(this);

this.handleValidateOldpassword = this.handleValidateOldpassword.bind(this);

 
  }

  componentWillMount(){

    this.getuserinfo();


  }

  getuserinfo(){
    var user=JSON.parse(localStorage.getItem('user'));

     
    const requestOptions = {
      headers: { 'access-token': user.token },
    };

    fetch(Config.API+'/api/user/'+user.data.account_id+'/'+user.data.user_id,requestOptions)
    .then( this.handleResponse)
      .then( user => this.setState({username:user.data.username,firstname:user.data.firstname,lastname:user.data.lastname,loading: false}));

     
  
    }



    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   
  }

  handleUpdated(){ this.props.handleClick("tr","success","Your password has been updated",<span data-notify='icon' className='pe-7s-check' />)}


handleUpdate(){ if(this.state.new_passwordError === null && this.state.new_password2Error === null && this.state.old_passwordError === null){
  var user=JSON.parse(localStorage.getItem('user'));
  var userid=user.data.user_id;
  var password=this.state.new_password;
   const requestOptions = {
     method: 'POST',
     headers: { 'Content-Type': 'application/json','access-token': user.token },
     body: JSON.stringify({ userid, password })
 };
 
 
   fetch(Config.API+'/api/updatepassword',requestOptions)
   .then(response => response.json())
   .then(data => data.status!="success"
   ? this.setState()
  : this.setState({old_password:"",new_password:"" ,new_password2:"",showModal: false},this.handleUpdated()))

}

}

async  handleSubmit(e) {

    await this.handleValidate();
    
 this.handleValidateOldpassword();
 
 
    }


handleValidateOldpassword(e) {

  var user=JSON.parse(localStorage.getItem('user'));
 var userid=user.data.user_id;
 var password=this.state.old_password;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json','access-token': user.token },
    body: JSON.stringify({ userid, password })
};


  fetch(Config.API+'/api/passwordverify',requestOptions)
  .then(response => response.json())
  .then(data => data.status!="success"
  ? this.setState({
    old_passwordError: (
        <small className="text-danger">Your current password is incorrect</small>
      )
        }) 
        : this.setState({old_passwordError: null },this.handleUpdate()))



 


}
  
  handleValidate(e) {

    
               this.state.new_password.length< 8
    ? this.setState({
     new_passwordError: (
          <small className="text-danger">Password must be at least 8 characters long</small>
        )
          })
          : this.state.new_password.search(/\d/) == -1
          ? this.setState({
            new_passwordError: (
                <small className="text-danger">Password must contain 1 number</small>
              )
                })
                : this.state.new_password.search(/[a-zA-Z]/) == -1
          ? this.setState({
            new_passwordError: (
                <small className="text-danger">Password must contain 1 letter</small>
              )
                })
               : this.setState({ new_passwordError: null });

               
               this.state.new_password2==""
               ? this.setState({
                new_password2Error: (
                    <small className="text-danger">Please confirm your password.</small>
                  )
                    })
               :this.state.new_password2.length< 8
               ? this.setState({
                 new_password2Error: (
                     <small className="text-danger">Password Must be at least 8 characters long</small>
                   )
                     })
                     : this.state.new_password2.search(/\d/) == -1
                     ? this.setState({
                       new_password2Error: (
                           <small className="text-danger">Password must contain 1 number</small>
                         )
                           })
                           : this.state.new_password2.search(/[a-zA-Z]/) == -1
                     ? this.setState({
                       new_password2Error: (
                           <small className="text-danger">Password must contain 1 letter</small>
                         )
                           })
                           : this.state.new_password != this.state.new_password2
                           ? this.setState({
                             new_password2Error: (
                                 <small className="text-danger">Password must match</small>
                               )
                                 })
                          : this.setState({ new_password2Error: null });
      
                          this.state.old_password.length < 1
                          ? this.setState({
                          old_passwordError: (
                               <small className="text-danger">Please enter your current password</small>
                             )
                               })
                               : this.setState({old_passwordError: null });

                               return
  }




  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        
        

        }
       

        return data;
    });
}


  render() {
    const { old_password,new_password,new_password2} = this.state;
    if (this.state.loading) return <Loader />
      return (
      <div className="main-content">
                      
        <Grid fluid>
       
          <Row>
         
       
                    <Col md={12}> 
              <Form horizontal >
                <Card
                  title="User Profile"
                  content={
                    <div>
              


              <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         User Name
                        </Col>
                        <Col  sm={6}>
                        <div className="textdisplay" >{this.state.username}</div>
                        </Col>
                      </FormGroup>


        <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         First Name
                        </Col>
                        <Col sm={6}>
                        <div className="textdisplay" >{this.state.firstname}</div>
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Last Name
                        </Col>
                        <Col  sm={6}>
                        <div className="textdisplay" > {this.state.lastname}</div>
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Password
                        </Col>
                        <Col sm={6}>
                        <Button  bsStyle="default" onClick={() => this.setState({ showModal: true })}>
                     Change Password
                        
                      </Button>
                      <Modal
                      show={this.state.showModal} backdrop="static"
                      onHide={() => this.setState({ showModal: false })}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Change Password</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      <Grid fluid>
                      <Row>
         
       
         <Col md={12}> 
         <Form horizontal onSubmit={this.handleSubmit}>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={4} smOffset={1}>
                      Current Password
                        </Col>
                        <Col sm={7}>
                        <FormControl
                            type="password"
                            name="old_password"
                            value={old_password}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.old_passwordError}
                        </Col>
                      </FormGroup>
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={4} smOffset={1}>
                        New Password
                        </Col>
                        <Col sm={7}>
                        <FormControl
                            type="password"
                            name="new_password"
                          value={new_password}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.new_passwordError}
                        </Col>
                      </FormGroup>
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={4} smOffset={1}>
                        Re Enter New Password
                        </Col>
                        <Col sm={7}>
                        <FormControl
                            type="password"
                            name="new_password2"
                            value={new_password2}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.new_password2Error}
                        </Col>
                      </FormGroup>
                      </Form></Col></Row></Grid>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          simple
                          onClick={() => this.setState({ showModal: false })}
                        >Close
                        </Button>
                        <Button
                         simple
                         onClick={() => this.handleSubmit()}
                         
                        >Save changes
                        </Button>
                      </Modal.Footer>
                    </Modal>
                        </Col>
                      </FormGroup>
                   
                    
                    </div>
                  }
      
                />
              </Form>
            </Col>
                 
                  </Row>
               
        </Grid>
      </div>
    );
  }
}

export default Profile ;
