import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import Login from "components/Login/Login.jsx";
import { userService } from 'helpers/user.service.jsx';
import logo from "logisticb.svg";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    userService.logout();
    this.state = {
      cardHidden: true,
      username: '',
      password: '',
      submitted: false,
      loading: false,
      error: '',
      showverifyemail:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
}

handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;

    // stop here if form is invalid
    if (!(username && password)) {
        return;
    }

    this.setState({ loading: true });
    userService.login(username, password)
        .then(
            user => {

              if (user.data.verified_email==true){
              const { from } = this.props.location.state || { from: { pathname: "/" } };
               this.props.history.push(from);
            //  window.location.href ="/#/dashboard";
          }else{
            this.setState({showverifyemail:true,loading:false,error:''})



          }
            },
     
            error => this.setState({ error, loading: false,showverifyemail:false })
        );
}

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }
  render() {
    const { username, password, submitted, loading, error } = this.state;
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
        
              <Login
                hidden={this.state.cardHidden}
                textCenter
                content={    <form name="form" onSubmit={this.handleSubmit}>
                  <div>  <img src={logo} alt="ALT5 COIN"  className="loginLogo" />
                  <h2>Sign in to your account</h2>
                  <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                  <label htmlFor="username">Email</label>
                  <input type="email" className="form-control" name="username" value={username} onChange={this.handleChange} />
                  {submitted && !username &&
                      <div className="help-block">Email is required</div>
                  }
              </div>
              <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                  <label htmlFor="password">Password</label> 
                  <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                  {submitted && !password &&
                      <div className="help-block">Password is required</div>
                  }
              </div>
              <div className="form-group">

              {this.state.showverifyemail
                      ?<div className={'alert alert-info'}>You must verify your email address before logging in.<br/> A Email to verify your account has been resent.<br/> Please check your inbox to verify your account.</div>
                      :''
                  }
                  <button className="btn btn-primary" disabled={loading}>Sign in</button>
                  {loading &&
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  }
              </div>
              {error &&
                  <div className={'alert alert-danger'}>{error}</div>
              }

<span className="forgotPass"><a href="/#/a/forgot-password">Forgot your password?</a></span>
                  </div>  </form>
                }
              
                ftTextCenter
              /> 
          
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default LoginPage;
