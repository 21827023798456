import React, { Component } from "react";
import {
 Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import Loader from "components/Loader/loader.jsx";
import {  userVerify} from 'helpers/user.verifyemail.jsx';
import logo from "logisticb.svg";

class   VerifyEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
    
     loading:true,
      submitted: false,
      reset: false,
    
      error: false,
   
 
 
    };


  }


  componentDidMount() {
    const userid=this.props.match.params.userid;
    const token=this.props.match.params.token;
    userVerify.verify(userid,token)
    .then(data => {
if (data.status=='success'){ 
this.setState({loading:false})}else{this.setState({loading:false,error:true})}
    }
    )
 
   
  }




  render() {  
    const {  loading, error} = this.state;
    if (this.state.loading) return <Loader />
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
          <img src={logo} alt="ALT5 COIN"  className="loginLogo" />
        {error==false 
        ?<div><h2>Your Email Has Been Verified</h2>
              You can now <a href="/#/a/login-page">Sign In</a> to your account</div>
          :<div><h2>Your link has expired</h2>
          Please <a href="/#/a/login-page">Sign In</a> to have a new link resent</div>}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default VerifyEmail;
