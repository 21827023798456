let Config = {
API:'https://coinsapi.logisticprotrade.com:5000',
 //API:'http://localhost:5000',
 Company:'Logistic Pro Trading',
 Website:'https://logisticprotrade.com',
 paymentApiKey:'E6638CA6-B726-42F0-BE28-8135238302ED',
 paymentWidget:'https://api.mobilum.com',
 supportEmail:'support@logisticprotrade.com',
 BtcTxUrl:'https://blockexplorer.one/btc/mainnet/tx/',
BchTxUrl:'https://blockexplorer.one/bch/mainnet/tx/',
LtcTxUrl:'https://blockexplorer.one/ltc/mainnet/tx/',
  EthTxUrl:'https://etherscan.io/tx/',
  networkFee:5,
  weeklimit:3000,
  dailylimit:1500,
  terms:'https://logisticprotrade.com/terms/',
  refund:'https://logisticprotrade.com/refund-policy/'
};
module.exports = Config;