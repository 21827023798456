
import { authHeader } from './auth-header.jsx';
const Config = require('config');
export const userService = {
    login,
    logout,
    forgot
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(Config.API+`/authenticate`, requestOptions)
      .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {

             // console.log(JSON.stringify(user))
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                user.authdata = window.btoa(username + ':' + password);
                if (user.data.verified_email==true){
                localStorage.setItem('user', JSON.stringify(user));
                var userd=JSON.parse(localStorage.getItem('user'));
                
            
                if (userd.data.kyc=='Pending'){
         
                    checkkyc(user.data.natural_person_id).then(function(){
                     // window.location.reload();
                  })
                   }}else{
var userid=user.data.user_id
var email=user.data.email
var firstname=user.data.firstname
var lastname=user.data.lastname
resendEmailVerify(firstname,lastname,email,userid)
   }
            }

          return user;  
        })
}

function  updateStorage(kyc){
    var user=JSON.parse(localStorage.getItem('user'));
  
    user.data.kyc=kyc
  
    localStorage.setItem('user',user)
  
  
  
  }
  

  function resendEmailVerify(firstname,lastname,email,userid){

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({firstname,lastname,email,userid})
  };

  return fetch(Config.API+`/resendemailverify`, requestOptions)
    .then(handleResponse)
      .then(user => {
          // login successful if there's a user in the response
          if (user) {
              // store user details and basic auth credentials in local storage 
              // to keep user logged in between page refreshes
            
          }

          return user;
      });

  }

function checkkyc(enityid){
  return new Promise( async resolve => {

    var user=JSON.parse(localStorage.getItem('user'));
    var token=user.token;


const requestOptions = {
 method: 'POST',
 headers: {'Content-Type': 'application/json','access-token': token},
 body: JSON.stringify({enityid})
};


fetch(Config.API+`/api/kyc/check`, requestOptions)
.then(handleResponse)
  .then(result => {
   console.log(result.data.status)
   var result=result.data.status
    if(result=='Approved'){

var kyc='Verified'
        var user=JSON.parse(localStorage.getItem('user'));
        var account_id=user.data.account_id;
      var token=user.token;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,'access-token': token},
           body: JSON.stringify({ kyc,account_id})
        };
            
      
       fetch(Config.API+`/api/kyc/verified`,requestOptions)
       .then(
        function(){
          var user=JSON.parse(localStorage.getItem('user'));
        
          user.data.kyc=kyc
        
          localStorage.removeItem('user')
          localStorage.setItem('user',JSON.stringify(user))
          resolve()
           })

    }
})
  .catch(error => console.log('error', error));
     

    })
}

function forgot(username) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({username})
    };

    return fetch(Config.API+`/api/users/forgot`, requestOptions)
      .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
              
            }

            return user;
        });
}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}
function handleErrorResponse(response) {

    return Promise.reject('Error');
}
function handleResponse(response) {
  
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

         // const error = (data && data.message);
          return Promise.reject('Username or password is incorrect');
        }

        return data;
    });
}


function handleResponse2(response) {
  
    return response.text().then(text => {
        const data = text && JSON.parse(text);

      // console.log(data)

        return data;
    });
}