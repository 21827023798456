import React, { Component } from "react";
import { Grid, Row, Col, Media, FormControl, FormGroup } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Card from "components/Card/Card.jsx";
import Reaptcha from 'reaptcha';
import Button from "components/CustomButton/CustomButton.jsx";
import Login from "components/Login/Reset.jsx";
import logo from "logisticb.svg";
const Config = require('config');
const queryString = require('query-string');
class RegisterPage extends Component {


  constructor(props,context) {
    super(props,context);

    this.state = {
      cardHidden: true,
      verified: false,
      submitted: false,
      created: false,
      error: '',
   message:'',
      firstnameError:null,
      lastnameError:null,
      emailError:null,
      passwordError:null,
      password2Error:null,
      firstname:"",
      lastname:"",
      affiliate_id:"",
      ref_url:"",
      email:"",
      password:"",
      password2:"",
      alert: null,
      show: false,
      disabledbtn:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit= this.handleSubmit.bind(this);
this.handleValidate = this.handleValidate.bind(this);
this.hideAlert = this.hideAlert.bind(this);


  }


  componentWillMount(){
const ref_url=window.location.hostname
    const parsed = queryString.parse(this.props.location.search);
  this.setState({affiliate_id:parsed.refid,ref_url:ref_url})
   
    
  }

  componentDidMount() {

    
    if (this.captchaCheck) {
       
      this.captchaCheck.reset();

  }
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }


  onVerify = recaptchaResponse => {
    this.setState({
      verified: true
    });
  };


  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
}


 async handleSubmit(e) {
  var {  firstname,lastname,email,password,affiliate_id,ref_url} = this.state;

if (affiliate_id===undefined){affiliate_id=''}
 
  await this.handleValidate();
  this.setState({disabledbtn:true},
    function(){
  this.createaccount(email,password,firstname,lastname,affiliate_id,ref_url)  }
  )

  }

  
 

createaccount(email,password,firstname,lastname,affiliate_id,ref_url){

  if( this.state.reCaptchaError === null && this.state.passwordError === null  && this.state.password2Error === null && this.state.firstnameError === null && this.state.lastnameError === null){

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify({ email,password,firstname,lastname,affiliate_id,ref_url})
    };
        
  
return fetch(Config.API+`/createaccount`,requestOptions)
.then( this.handleResponse)
   .then( account => this.setState({created:true,message:account.data.message},this.messagealert(account.data.message)))
  

  }else{this.setState({disabledbtn:false})}
}


successAlert() {
  this.setState({
    alert: (
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Your account has been created!"
        onConfirm={() => window.location.href = "/"}
    
        confirmBtnBsStyle="info"
      >
        Please Check Your Email to Verify Your Accounts Email
      </SweetAlert>
    )
  });
}

existAlert() {
  this.setState({
    alert: (
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={'An account already exists for '+this.state.email}
        onConfirm={() => window.location.href = "/"}
   
        confirmBtnBsStyle="info"
      >
       Please Sign in!
      </SweetAlert>
    )
  });
}

hideAlert() {
  this.setState({
    alert: null
  });
}
messagealert(message){
  if(message==='created'){
  
    this.successAlert()
  }else{
 
    this.existAlert()
  }

}



handleResponse(response) {

  return response.text().then(text => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
          
       // const error = (data && data.message);
        return 'error'
      }else{
      

      }
     

      return data;
  });
}


handleValidate(e) {

    
  this.state.password.length< 8
? this.setState({
passwordError: (
<small className="text-danger">Password must be at least 8 characters long</small>
)
})
: this.state.password.search(/\d/) == -1
? this.setState({
passwordError: (
   <small className="text-danger">Password must contain 1 number</small>
 )
   })
   : this.state.password.search(/[a-zA-Z]/) == -1
? this.setState({
passwordError: (
   <small className="text-danger">Password must contain 1 letter</small>
 )
   })
  : this.setState({ passwordError: null });

  
  this.state.password2==""
  ? this.setState({
   password2Error: (
       <small className="text-danger">Please confirm your password.</small>
     )
       })
  :this.state.password2.length< 8
  ? this.setState({
    password2Error: (
        <small className="text-danger">Password Must be at least 8 characters long</small>
      )
        })
        : this.state.password2.search(/\d/) == -1
        ? this.setState({
          password2Error: (
              <small className="text-danger">Password must contain 1 number</small>
            )
              })
              : this.state.password2.search(/[a-zA-Z]/) == -1
        ? this.setState({
          password2Error: (
              <small className="text-danger">Password must contain 1 letter</small>
            )
              })
              : this.state.password != this.state.password2
              ? this.setState({
                password2Error: (
                    <small className="text-danger">Password must match</small>
                  )
                    })
             : this.setState({ password2Error: null });

             var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
             re.test(this.state.email) === false
               ? this.setState({
                emailError: (
                     <small className="text-danger">
                       This must be a valid email address.
                     </small>
                   )
                 })
               : this.setState({ emailError: null });


               this.state.firstname=== ""
               ? this.setState({
                 firstnameError: (
                     <small className="text-danger">field is  required.</small>
                   )
                     })
               : this.setState({ firstnameError: null });


               this.state.lastname=== ""
               ? this.setState({
                lastnameError: (
                     <small className="text-danger">field is  required.</small>
                   )
                     })
               : this.setState({ lastnameError: null });

         
         

               this.state.recaptcha===false
               ? this.setState({
                reCaptchaError: 'notVerified'
                     })
               : this.setState({ reCaptchaError: null });

                  return
}

  render() {
    const {  firstname,lastname,email,password,password2,alert,disabledbtn,affiliate_id } = this.state;
   
    return (
     
      <Grid> {alert}
        <Row>

       
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
        


            <form>
            <Login
                hidden={this.state.cardHidden}
                textCenter
                content={ 
                  <div>
                    <h2>Create your account</h2>
                    <FormGroup>
                      <FormControl type="text" 
                      
                      name="firstname"
                      value={firstname}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}

                      placeholder="Your First Name" />
                       {this.state.firstnameError}
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="text" 
                    name="lastname"
                        onBlur={this.handleValidate}
                        onChange={this.handleChange}

                        value={lastname}
                      placeholder="Your Last Name" />
                         {this.state.lastnameError}
                    </FormGroup>
                    
                    <FormGroup>
                      <FormControl type="email" 
                           value={email}
                      name="email"
                      onBlur={this.handleValidate}
                      onChange={this.handleChange}
                      placeholder="Enter Email" />
                        {this.state.emailError}
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="password"
                                              value={password}
                      name="password"
                      onBlur={this.handleValidate}
                      onChange={this.handleChange}
                      placeholder="Password" />
                        {this.state.passwordError}
                    </FormGroup>
                    <FormGroup>
                      <FormControl
                        type="password"
                        value={password2}
                        name="password2"
                        onBlur={this.handleValidate}
                        onChange={this.handleChange}


                        placeholder="Password Confirmation"
                      />
                        {this.state.password2Error}
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="text" 
                           value={affiliate_id}
                      name="affiliate_id"
 onChange={this.handleChange}
                      placeholder="Referral Code"
                     />
                   
                    </FormGroup>
                    <FormGroup>
                    <Reaptcha sitekey="6LdO29EZAAAAACstEeOX00Ty8bwmcP5JELl6HZ7Y" onVerify={this.onVerify} />
                      
                  </FormGroup>
                    
                  </div>
                }
                ftTextCenter
                legend={
                  !disabledbtn
                                   ?<Button wd fill neutral  
                                    onClick={() => this.handleSubmit()}>
                                      Create your account
                                    </Button>
                                    
                                    :<Button wd fill neutral  
                                    disabled>
                                      Create your account
                                    </Button>
                                  }
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default RegisterPage;
