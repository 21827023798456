import Exchange from "views/Exchange/Exchange.jsx";
import Verify from "views/Verify/Verify.jsx";
import Pending from "views/Verify/Pending.jsx";
import Transactions from "views/Transactions/Transactions.jsx";
import Success from "views/Exchange/success.jsx";
import Fail from "views/Exchange/fail.jsx";

import Profile from "views/Profile/profile.jsx";







import pagesRoutes from "./pages.jsx";



var dashboardRoutes = [
  {
 
    path: "/verify",
    name: "Verify Account",
    verify:true,
    icon: "fas fa-check-double",
    component: Verify
   
  },
  {
    menuhide:true,
    path: "/pending",
    name: "Account's Pending",
    verify:true,
    icon: "fas fa-check-double",
    component: Pending
   
  },
{
 
    path: "/exchange",
    name: "Exchange",
    icon: "fas fa-coins",
    component: Exchange
   
  },
  


 
      {
      
        path: "/Transactions",
        name: "Transactions",
        icon: "fas fa-exchange-alt",
        component: Transactions
       
      },
      {
        menuhide:true,
        path: "/successful_payment",
        name: "Successful Payment",
        icon: "fas fa-exchange-alt",
        component: Success
       
      },
      {
        menuhide:true,
        path: "/payment_failed",
        name: "Payment Failed",
        icon: "fas fa-exchange-alt",
        component: Fail
       
      },
      {
        menuhide:true,
        path: "/profile",
        name: "Profile",
        component: Profile
      },

      
    
     

    

      
      
  { redirect: true, path: "/", pathTo: "/exchange", name: "Home" }


];
export default dashboardRoutes;
