import React, { Component } from "react";
import { Grid,   Col,Row,FormControl, FormGroup, ControlLabel,InputGroup} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
const queryString = require('query-string');
const Config = require('config.js');


class Success extends Component {

constructor(props) {
  super(props);

  this.state = {
    transaction_id:'',
    address:'',
    coin:'',
    price:'',
    amount:'',
    fiat:'',
    loading:true

  }

  
}


componentWillMount(){


  const search = this.props.location.search; // could be '?foo=bar'
const params = new URLSearchParams(search);
const additionalData = params.get('additionalData')


 

  this.setState({transaction_id:additionalData},function(){

    this.getTransaction(this.state.transaction_id)
  })
  }

  getTransaction=(tid)=>{

    var user=JSON.parse(localStorage.getItem('user'));
    var aid=user.data.account_id;
    var tid=tid;
    var token=user.token;
    const requestOptions = {
      headers: {'access-token': token},
   };
      


    fetch(Config.API+'/api/transaction/'+aid+'/'+tid,requestOptions)
    .then( this.handleResponse)
    .then( 
      transactiondata => this.setState({address:transactiondata.data.address,
    coin:transactiondata.data.coin,
    price:transactiondata.data.final_amount.toFixed(2),
    amount:transactiondata.data.crypto_final_amount,
    fiat:transactiondata.data.fiat},function(){}
    ));
   

  }

 

  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
  }


  render() {
    const {address,coin,fiat,price,amount} = this.state; 


    return (

      
      <div className="main-content">
   
        <Grid fluid>
          <Row >
        <Col lg={12}>   <h3 className="header">Successful Transaction</h3></Col>
        
        <Col lg={12}>
          
        <Card 

                  content={
                    <Row >
                 
               

                      
          <Col sm={12} md={12}>
         Congratulation your transaction of {price} {fiat} was successful.<br/>You will receive an email when the {amount} {coin} have been sent to your wallet address {address}.
        <br/> Please see the transactions section for the TXID and Transfer Status.<br/> If you have any questions please contact support at <a href={'mailto:'+Config.supportEmail}>{Config.supportEmail}</a>   </Col> </Row>
                  } />
                     </Col>
  

   
          </Row>
        </Grid>


      </div>
    );
  }
}

export default Success;
