import React from "react";
import ReactDOM from "react-dom";
import {HashRouter, Route, Switch } from "react-router-dom";
import { PrivateRoute } from './helpers/PrivateRoute';
import indexRoutes from "./routes/index.jsx";




import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/logisticprotrading-dashboard.css?v=1.1.1";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";

ReactDOM.render(
  <HashRouter>
    <Switch> 
      
      {indexRoutes.map((prop, key) => {
        if(prop.public){
      
        return <Route path={prop.path} component={prop.component} key={key} />;}{
      
          return <PrivateRoute path={prop.path} component={prop.component} key={key} />;
        }
      }
        )
      }
    
    </Switch>
    </HashRouter>,
  document.getElementById("root")
);
