import React, { Component } from "react";
import Loader from "components/Loader/overlayloader.jsx";
const Config = require('config');

var statusCheck=''

class Widget extends Component {

    // create a state structure to store values from the api
    state = {
        widget: "",
        scriptUrl: "",
        scriptCss: "",
 icomplyuuid:this.props.icomplyuuid,
        pay:this.props.pay,
        tid:this.props.tid,
        transstatus:"",
        loading:true,
        errormsg:""
    };


    componentWillUnmount() {
 
        clearInterval(statusCheck);
 
        }


    componentDidMount() {

        // The following data is fixed as an example,
        // in actual case this must be set based on 
        // the current user from the hosting site
      

        // Step 1 - initiate the data
        var user=JSON.parse(localStorage.getItem('user'));


        // Step 2 - call the api 
        const requestOptions = {
            method: 'POST',
            headers: {"apiKey":Config.paymentApiKey,"Content-Type":"application/json"},
            body: '{"Email":"'+user.data.email+'"}'
        };
       //console.log("apiKey:"+Config.paymnetApiKey+",Content-Type : application/json")
    //console.log(Config.paymentWidget+"/widget/GetWidgetWithOrderPrice?userIdentifier="+this.state.icomplyuuid+"&currency=USD&price="+this.state.pay+"&additionalData="+this.state.tid)
         fetch( Config.paymentWidget+"/widget/GetWidgetWithOrderPrice?userIdentifier="+this.state.icomplyuuid+"&currency=USD&price="+this.state.pay+"&additionalData="+this.state.tid, requestOptions)
            .then(response => response.json())
            .then(data => {     
               // console.log(this.state.icomplyuuid)
          //console.log(JSON.stringify(data))   
         // {"redirectUrl":null,"responseType":null,"followUrl":null,"success":false,"result":null,"errors":[{"message":"Your account is currently under review, please contact the customer support at support@mobilum.com","code":14}]}        
          if (data.result!=null) {     this.setState({
                    loading:false,
                   widget: atob(data.result.widgetBase64Html),
                   scriptUrl: atob(data.result.widgetBase64ScriptUrl),
                   styleUrl: atob(data.result.widgetBase64CssUrl), 
                },function(){
      //console.log(this.state.styleUrl)
//console.log(this.state.scriptUrl)
//console.log(this.state.widget)

statusCheck=setInterval(()=>{ 
    this.checkTransactionStatus()} , 3000);

//console.log(this.state.widget)
                })

                // Step 3 - Load the data based on the result of the api
                const script = document.createElement("script");
                script.async = true;
                script.src = this.state.scriptUrl;

                var mobilum_widget = document.getElementById("mobilum-container");
                mobilum_widget.appendChild(script);

            }else{
                this.setState({loading:false,errormsg:data.errors[0].message})
              
            }
               
            });
    }


checkTransactionStatus=()=>{

    var tid=this.state.tid
    
    
    //console.log('{"asset":"'+pairing+'","price":'+price+'}')
        return new Promise( async resolve => {
      
          //console.log(email)
          
          var user=JSON.parse(localStorage.getItem('user'))
          var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("access-token", user.token);
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({tid})};
      
      fetch(Config.API+`/api/transactionstatus`, requestOptions)
      .then( this.handleResponse)
        .then(result => this.setState({ transstatus:result.data},function() {

            if(this.state.transstatus=='APPROVED'){window.location.href='/#/successful_payment?additionalData='+tid}
            else if(this.state.transstatus=='DECLINED' || this.state.transstatus=='NO_3DS_AUTHENTICATION' || this.state.transstatus=='REJECTED'){
                window.location.href='/#/payment_failed'}
            
      
       
    
          resolve()
            })
        )
        }
        
        
        )
      
 
      
      }

      
  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}


    render() {
        return (            
            <div id="mobilum-container">
          { (this.state.loading) ?<Loader /> :'' }
             {this.state.errormsg!='' ?<div>{this.state.errormsg}</div> :<div dangerouslySetInnerHTML={{ __html: this.state.widget }} />  }              
            </div>
        );
    }
}

export default Widget;
