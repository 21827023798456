import React, { Component } from "react";
import { Grid,   Col,Row,FormControl, FormGroup, ControlLabel,InputGroup} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
const queryString = require('query-string');
const Config = require('config.js');


class Fail extends Component {

constructor(props) {
  super(props);

  this.state = {
    transaction_id:'',
    address:'',
    coin:'',
    price:'',
    amount:'',
    fiat:'',
    loading:true,
    status:''

  }

  
}


componentWillMount(){


  const search = this.props.location.search; // could be '?foo=bar'
const params = new URLSearchParams(search);
const additionalData = params.get('additionalData')


 

  this.setState({transaction_id:additionalData},function(){

    this.getTransaction(this.state.transaction_id)
  })
  }

  getTransaction=(tid)=>{

    var user=JSON.parse(localStorage.getItem('user'));
    var aid=user.data.account_id;
    var tid=tid;
    var token=user.token;
    const requestOptions = {
      headers: {'access-token': token},
   };
      


    fetch(Config.API+'/api/transaction/'+aid+'/'+tid,requestOptions)
    .then( this.handleResponse)
    .then( 
      transactiondata => this.setState({status:transactiondata.data.m_status},function(){}
    ));
   

  }

 

  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
  }


  render() {
    const {status} = this.state; 


    return (

      
      <div className="main-content">
   
        <Grid fluid>
          <Row >
        <Col lg={12}>   <h3 className="header">Transaction Failed</h3></Col>
        
        <Col lg={12}>
          {status=='DECLINED'
        ?<Card 
content={
                    <Row ><Col sm={12} md={12}>
         Unfortunately the card issuing bank has declined this transaction.<br/>Please contact your bank to resolve the issue and then try your purchase again.
<br/> If you have any questions please contact support at <a href={'mailto:'+Config.supportEmail}>{Config.supportEmail}</a>  
  </Col> </Row>
                  } />

                  :status=='REJECTED'
                ?<Card 
                content={
                                    <Row ><Col sm={12} md={12}>
                          Something went wrong and some validation error occurred.<br/> Please try again.
                <br/> If you have any questions please contact support at <a href={'mailto:'+Config.supportEmail}>{Config.supportEmail}</a>  
                  </Col> </Row>
                                  } /> 
                  
                                  :status=='NO_3DS_AUTHENTICATION'
                                  ?<Card 
                                  content={
                                                      <Row ><Col sm={12} md={12}>
                                          Cardholder did not login to bank in 15 minutes since transaction initiated.<br/> Please try again.
                                  <br/> If you have any questions please contact support at <a href={'mailto:'+Config.supportEmail}>{Config.supportEmail}</a>  
                                    </Col> </Row>
                                                    } /> 
                                    
                                    :<Card 
                                    content={
                                                        <Row ><Col sm={12} md={12}>
                                              Sorry, your transaction failed.<br/> Please try again.
                                    <br/> If you have any questions please contact support at <a href={'mailto:'+Config.supportEmail}>{Config.supportEmail}</a>  
                                      </Col> </Row>
                                                      } />}
                     </Col>
  

   
          </Row>
        </Grid>


      </div>
    );
  }
}

export default Fail;
