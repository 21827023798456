import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Datetime from "react-datetime";
import Button from "components/CustomButton/CustomButton.jsx";



import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
const getCountryISO3 = require("country-iso-2-to-3");
const Config = require('config');
const moment = require('moment');

class AccountInfo extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      loading: true,
      alert: null,
      show: false,
      account_id:null,
      account_first_name:'',
      account_last_name:'',
      account_email:"",
      account_phone:"",
      account_address:"",
      account_city:"",
      account_prov_state:"",
      account_postal_zip:"",
      account_country:"",
      account_dob:"",
      account_dobError:null,
      account_first_nameError: null,
      account_last_nameError: null,
      account_emailError: null,
      account_phoneError: null,
      account_addressError: null,
      account_cityError: null,
      account_prov_stateError: null,
  kyc:false,
      account_postal_zipError: null,
      account_countryError: null,
      Page_Error:null,
showIcomply:false,
showInfo:true,
icomplydata:""
      };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleValidate = this.handleValidate.bind(this);

 
  }

  componentWillMount(){

  
    var user=JSON.parse(localStorage.getItem('user'));
    if (user.data.kyc=='Verified' || user.data.kyc=='Pending' ){this.setState({kyc:true,showInfo:false,loading:false})}else{
      this.setState({kyc:false},function(){
        this.getaccountinfo();
      })}
  
    
//this.setState({Countries:Countriesdata.data})




  }

  


  getaccountinfo(){
    var user=JSON.parse(localStorage.getItem('user'));
   
    const requestOptions = {
      headers: { 'access-token': user.token },
    };

    fetch(Config.API+'/api/account/'+user.data.account_id,requestOptions)
    .then( this.handleResponse)
      .then( account => this.setState({account_first_name:account.data.name,account_last_name:account.data.last_name,account_email:account.data.email,account_phone:account.data.phone,account_city:account.data.city,account_country:account.data.country,account_postal_zip:account.data.postal_zip,account_phone:account.data.phone,account_prov_state:account.data.prov_state,account_address:account.data.address,account_id:user.data.account_id,account_dob:moment(account.data.dob).format('MM/DD/YYYY'), loading: false}));

    
    }


    updateaccountinfo(name,last_name,address,city,prov_state,postal_zip,email, country,phone,dob){
      var user=JSON.parse(localStorage.getItem('user'));
      var account_id=user.data.account_id;
var token=user.token;
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,'access-token': token},
         body: JSON.stringify({ name,last_name,address,city,prov_state,postal_zip,email,country,phone,account_id,dob})
      };
          
    
  return fetch(Config.API+`/api/account/update`,requestOptions)
     .then( this.handleResponse)
     .then(this.loadIcomply())
    
    
  }







loadIcomply(){


  this.setState({showIcomply:true,showInfo:false})
  const styles = document.createElement("link");
  styles.href = "https://verify.icomplykyc.com/main.css";
  styles.async = true;
  styles.rel='stylesheet';
  styles.type='text/css';

  document.head.appendChild( styles);



  const script = document.createElement("script");
  script.src = "https://verify.icomplykyc.com/icomply-min.js";
  script.async = true;
  script.onload = () => this.scriptLoaded();

  document.body.appendChild(script);


}


scriptLoaded() {


  this.setState({loading:false})

       const data = {
    
          firstName: ""+this.state.account_first_name+"",
          middleName: "", // optional
          lastName: ""+this.state.account_last_name+"",
          dateOfBirth: ""+moment(this.state.account_dob).format('YYYY-MM-DD')+"", // iso date format YYYY-MM-DD
          email:  ""+this.state.account_email+"",
          phoneNumber:  ""+this.state.account_phone+"", // optional
          address:  ""+this.state.account_address+"",
          unitNumber: "", // optional
          city:  ""+this.state.account_city+"",
          countryCode:  ""+getCountryISO3(this.state.account_country)+"", // Alpha-3 code format
          state:  ""+this.state.account_prov_state+"", // This field is required only if countryCode value
                       // is either 'CAN' or 'USA' - Alpha-2 code format
          zip:  ""+this.state.account_postal_zip+"" // optional
        };
        window.iComply.init(document.getElementById("icomply"), {
          data: data,
          icomplyPreview: false,
	      callback: function(data) {



//console.log(data)
   

     var kyc='Pending'
             



  var user=JSON.parse(localStorage.getItem('user'));
  var account_id=user.data.account_id;
var token=user.token;
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' ,'access-token': token},
     body: JSON.stringify({ kyc,account_id})
  };
      

 fetch(Config.API+`/api/kyc/update`,requestOptions)
 .then(
   function(){

  
  var user=JSON.parse(localStorage.getItem('user'));

  user.data.kyc=kyc
  
  localStorage.removeItem('user')
  localStorage.setItem('user',JSON.stringify(user))
  window.location.href ="/#/pending";


   }
 )



            // Your code goes here
          }
        });




  }
  
    

  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
        //  console.log(data.status)
          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
          
               return 'error'
          }
        

        }
       

        return data;
    });
}


    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   
  }



  
  handleValidate(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    re.test(this.state.account_email) === false
      ? this.setState({
        account_emailError: (
            <small className="text-danger">
              This must be a valid email address.
            </small>
          )
        })
      : this.setState({ account_emailError: null });
    
      this.state.account_address === ""
      ? this.setState({
        account_addressError: (
            <small className="text-danger">field is  required.</small>
          )
            })
      : this.setState({ account_addressError: null });

        this.state.account_phone=== ""
        ? this.setState({
          account_phoneError: (
              <small className="text-danger">a valid phone number is required.</small>
            )
              })
        : this.setState({ account_phoneError: null });

      this.state.account_last_name === ""
      ? this.setState({
        account_last_nameError: (
            <small className="text-danger">field is  required.</small>
          )
           })
      : this.setState({ account_last_nameError: null });
     



      this.state.account_first_name === ""
      ? this.setState({
        account_first_nameError: (
            <small className="text-danger">field is  required.</small>
          )
           })
      : this.setState({ account_first_nameError: null });


      this.state.account_prov_state === ""
      ? this.setState({
        account_prov_stateError: (
            <small className="text-danger">field is  required.</small>
          )
           })
      : this.setState({ account_prov_stateError: null });
      
  
      this.state.account_postal_zip === ""
      ? this.setState({
        account_postal_zipError: (
            <small className="text-danger">field is  required.</small>
          )
           })
      : this.setState({ account_postal_zipError: null });
    
      this.state.account_country === ""
      ? this.setState({
        account_countryError: (
            <small className="text-danger">field is  required.</small>
          )
           })
      : this.setState({ account_countryError: null });
    
      this.state.account_city === ""
      ? this.setState({
        account_cityError: (
            <small className="text-danger">field is  required.</small>
          )
          })
      : this.setState({ account_cityError: null });

      
               
      this.state.account_dob==""
      ? this.setState({
     account_dobError: (
           <small className="text-danger">a due date is required</small>
         )
           })
          : moment(this.state.account_dob, 'MM/DD/YYYY', true).isValid()
          ?  this.setState({ account_dobError: null
        
                  })
          : this.setState({     account_dobError: (
            <small className="text-danger">a valid date is required</small>
          ) });

  
  }

  handleSubmit(e) {
    e.preventDefault();
   
    const { account_first_name,account_last_name,account_address,account_city,account_prov_state,account_postal_zip,account_email,account_country,account_phone,account_dob} = this.state;
   if( this.state.account_cityError === null && this.state.account_last_nameError  === null && this.state.account_first_nameError  === null && this.state.account_addressError  === null && this.state.account_prov_stateError  === null && this.state.account_postal_zipError  === null && this.state.account_countryError  === null && this.state.account_emailError  === null && this.state.account_phoneError  === null&& this.state.account_dobError  === null){
     
  this.updateaccountinfo(account_first_name,account_last_name,account_address,account_city,account_prov_state,account_postal_zip,account_email,account_country,account_phone,account_dob)}

   }

   handleChangeDate(e) {

    this.setState({account_dob:moment(e).format('MM/DD/YYYY')})

}



selectCountry (val) {
  this.setState({ account_country: val });
}

selectRegion (val) {
  this.setState({ account_prov_state: val });
}

  render() {
    const { kyc,account_first_name,account_last_name,account_email,account_dob,account_address,account_city,account_prov_state,account_postal_zip, account_country, account_phone, account_id,loading,showIcomply,showInfo,icomplydata} = this.state;
  

  if (account_dob==''){ this.setState({account_dob:moment().format('MM/DD/YYYY')})}


    if (loading) return <Loader />
     return (
      <div className="main-content">


 <Grid fluid>
   
          <Row>
         
                    <Col md={12}> 


                   
                    {kyc==true?    
            <div><h2>Account Verification</h2><br/>
             Verification can take up to 48 hours. You will receive an email with the status of your verification.<br/>
            You can also check by re-logging in.<br/>
            If you have any questions please contact <a href={'mailto:'+Config.supportEmail}>{Config.supportEmail}</a></div>
            :''
                    }


                {showInfo==true?    <div>
              <Form horizontal onSubmit={this.handleSubmit} autoComplete="off">
                <Card
            
                  content={
                    <div>






                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         First Name
                        </Col>
                        <Col sm={6}>
                          <FormControl autoComplete="new-password"
                            type="text"
                            name="account_first_name"
                          value={account_first_name}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.account_first_nameError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Last Name
                        </Col>
                        <Col sm={6}>
                          <FormControl
                          autoComplete="new-password"
                            type="text"
                            name="account_last_name"
                          value={account_last_name}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.account_last_nameError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       Date of Birth
                        </Col>
                        <Col  sm={6}>
                        <Datetime
                     dateFormat="MM/DD/YYYY"
                       onChange={this.handleChangeDate}
                        timeFormat={false}
                        inputProps={{  name:"account_dob",placeholder: "Date Picker Here" }}
                        defaultValue={account_dob}
                      />

                    
                         {this.state.account_dobError}
                        </Col>


                      </FormGroup>

                      
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Address
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="account_address"
                          value={account_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.account_addressError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        City
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="account_city"
                          value={account_city}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.account_cityError}
                        </Col>
                      </FormGroup>

                

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     Country
                        </Col>
                        <Col sm={6}>

                       
                        <div className="countrySelect">  <CountryDropdown
                        valueType="short"
          value={account_country}
          onChange={(val) => this.selectCountry(val)} /></div>


                          {this.state.account_countryError}
                        </Col>
                      </FormGroup>
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       Province/State
                        </Col>
                        <Col sm={6}>
                      <div className="countrySelect"> <RegionDropdown
countryValueType="short"
valueType="short"
          country={account_country}
          value={account_prov_state}
          onChange={(val) => this.selectRegion(val)} /></div>
                          {this.state.account_prov_stateError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Postal/Zip Code
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="account_postal_zip"
                          value={account_postal_zip}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.account_postal_zipError}
                        </Col>
                      </FormGroup>



                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       Phone Number
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="account_phone"
                          value={account_phone}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.account_phoneError}
                        </Col>
                      </FormGroup>




                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                          Email
                        </Col>
                        <Col sm={6}> 
                          <FormControl
                            type="email"
                            value={account_email}
                            name="account_email"
                         onBlur={this.handleValidate}
                         onChange={this.handleChange}
                          />
             { this.state.account_emailError}
                        </Col>
                      </FormGroup>
                         
                   
                    
                    </div>
                  }
    
                  legend={
                    <Button
                      fill
                      bsStyle="info"
                      type="submit"
                      onClick={this.handleValidate}
                    >
                    Next
                    </Button>
                  }
                />
              </Form>
              </div>
              :<div></div>}
               {showIcomply==true? 
              <div><div id="icomply"></div>
<div>{icomplydata}</div></div>
              :<div></div>}



            </Col>
                 
                  </Row>
               
        </Grid>
      </div>
    );
  }
}

export default AccountInfo ;
