import React, { Component } from "react";
import { Grid,   Form, 
    Modal,
    FormGroup,
    FormControl,InputGroup,
    
    ControlLabel,  Col,Row } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { v4 as uuidv4 } from 'uuid';
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Widget from "components/Paynow/PaymentWidget.jsx";

var WAValidator = require('multicoin-address-validator');
var valid = require("card-validator");
const moment = require('moment-timezone');
const Config = require('config');
const publicIp = require("react-public-ip");
var priceCheckInt=''
var paymetCheckInt=''


class Paynow extends Component {
 
    constructor(props) {
        super(props);
      
        this.state = {
            showPaynow: false,
            weeklyWarning: '',
           dailyWarning: '',
            cryptoCurrencyName:this.props.CryptoName,
            BuyNowDisabled:this.props.BuyNowDisabled,
           kyc:this.props.kyc,
           Pay:this.props.Pay,
           Receive:'0.00',
           Authtoken:'',
           first_name:'',
           last_name:'',
           email:"",
           phone:"",
           address:"",
           city:"",
           prov_state:"",
           postal_zip:"",
           country:"",
           uuid:"",
           walletDisabled:true,
           displayPrice:false,
           step:1,
           wallet_address:'',
           wallet_address2:'',
           walletError:null,
           wallet2Error:null,
           approveDisabled:true,
           amountDisabled:true,
           fiatCurrency:this.props.FiatCurrency,
           cryptoCurrency:this.props.CryptoCurrency,
           widget:'',
           scriptUrl:'',
           scriptUrl:'',
           icomplyuuid:'',
           txid:'',
           ip:'',
priceLocked:false,
paymentApproved:false,
rate:'0.000000',
feeamount:'0.000000',
finalamount:'0.000000',
twentyfour:0,
week:0,
terms:Config.terms,
refund:Config.refund
        }
   
        this.handleChange = this.handleChange.bind(this);
        this.handleWalletValidate  = this.handleWalletValidate.bind(this);
        this.handleAgree  = this.handleAgree.bind(this);
      }



      nextStep = () => {
        const { step } = this.state
        this.setState({
            step : step + 1
        })
    }

    prevStep = () => {
        const { step } = this.state
        this.setState({
            step : step - 1
        },function(){
          if(step>=2){
            clearInterval(priceCheckInt)
            this.setState({priceLocked:false,walletDisabled:true,amountDisabled:true})}
        })
    }



      Paynow=()=>{ 
        clearInterval(priceCheckInt)
        clearInterval(paymetCheckInt)

        const namespace = 'c144ac7c-d0fb-487b-ab6f-d03b82d85b6b';
        var current_date = (new Date()).valueOf().toString();
        var random = Math.random().toString();
        var tid  = uuidv4(current_date + random, namespace);
        this.getamountlimits().then(()=>{
   

        if (this.state.week+parseFloat(this.state.Pay)>Config.weeklimit ||this.state.twentyfour+parseFloat(this.state.Pay)>Config.dailylimit){
       

          if (this.state.week+parseFloat(this.state.Pay)>Config.weeklimit){
this.setState({weeklyWarning:'<div class="warning">Sorry, this purchase puts you over your 7 day limit of $'+Config.weeklimit+'</div><br/>'})
          }else{
            this.setState({dailyWarning:'<div class="warning">Sorry, this purchase puts you over your 24 hour limit of $'+Config.dailylimit+'</div><br/>'})
                      }
        }else{
        this.getaccountinfo().then(
    
      

          this.setState({tid:tid,showPaynow:true,wallet_address:'',wallet_address2:'',step:1,priceLocked:false,walletDisabled:true,amountDisabled:true,rate:'0.000000',
          feeamount:'0.000000',
          finalamount:'0.000000'},function(){

       
        })
         )}
      }
      
      )
 

 } 



componentDidMount() {

  publicIp.v4().then(
     data => this.setState({ip:data})
   )
  

}



        
      

componentWillUnmount() {
 
  clearInterval(priceCheckInt)
  clearInterval(paymetCheckInt)

  }

  




        componentDidUpdate(prevProps) {
          // Typical usage (don't forget to compare props):
          if (this.props.BuyNowDisabled !== prevProps.BuyNowDisabled) {

            this.setState({BuyNowDisabled:this.props.BuyNowDisabled})
           
          }

         


          if (this.props.CryptoCurrency !== prevProps.CryptoCurrency) {

            this.setState({cryptoCurrency:this.props.CryptoCurrency})
           
          }

          if (this.props.FiatCurrency !== prevProps.FiatCurrency) {

            this.setState({fiatCurrency:this.props.FiatCurrency})
           
          }



          if (this.props.kyc !== prevProps.kyc) {

            this.setState({kyc:this.props.kyc})
           
          }


          if (this.props.CryptoName !== prevProps.CryptoName) {

            this.setState({cryptoCurrencyName:this.props.CryptoName})
           
          }

          if (this.props.kyc !== prevProps.kyc) {

            this.setState({kyc:this.props.kyc})
           
          }

          if (this.props.Pay !== prevProps.Pay) {

            this.setState({Pay:this.props.Pay,weeklyWarning: '',
            dailyWarning: ''})
           
          }
      
          
        }



    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   
  }

      







  getamountlimits=()=>{

    return new Promise( async resolve => {
    var user=JSON.parse(localStorage.getItem('user'));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("access-token", user.token);
    
   //console.log(user)
        var requestOptions = {
        headers: myHeaders};
  
    fetch(Config.API+'/api/checkamountlimits/'+user.data.account_id,requestOptions)
    .then( this.handleResponse)
      .then( limits => this.setState({twentyfour:limits.data.twentyfour,week:limits.data.week},function(){
      
     //console.log(this.state.week)
           
     //console.log(this.state.twentyfour)
        resolve()
      }));
    })
  
    }
  
    

getaccountinfo=()=>{

  return new Promise( async resolve => {
  var user=JSON.parse(localStorage.getItem('user'));
 //console.log(user)
  const requestOptions = {
    headers: { 'access-token': user.token },
  };

  fetch(Config.API+'/api/account/'+user.data.account_id,requestOptions)
  .then( this.handleResponse)
    .then( account => this.setState({icomplyuuid:account.data.natural_person_id},function(){
    
   //console.log(this.state.icomplyuuid)
      resolve()
    }));
  })

  }







  getPrice=()=>{

    var pairing=this.props.CryptoCurrency+'_'+this.props.FiatCurrency
      var price=this.props.Pay
    
    
    //console.log('{"asset":"'+pairing+'","price":'+price+'}')
        return new Promise( async resolve => {
      
          //console.log(email)
          
          var user=JSON.parse(localStorage.getItem('user'))
          var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("access-token", user.token);
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: '{"asset":"'+pairing+'","price":'+price+'}'};
      
      fetch(Config.API+`/api/getcryptoprice`, requestOptions)
      .then( this.handleResponse)
        .then(result => {
      if(result.data.crypto!=0){
        this.setState({Receive:result.data.crypto,rate:result.data.rate,feeamount:(Config.networkFee/result.data.rate).toFixed(6),finalamount:(result.data.crypto-(Config.networkFee/result.data.rate)).toFixed(6)},function(){resolve()})
        
      }else{
    this.setState({showPaynow:false,Receive:result.data.crypto,rate:result.data.rate,feeamount:(Config.networkFee/result.data.rate).toFixed(6),finalamount:(result.data.crypto-(Config.networkFee/result.data.rate)).toFixed(6)},function(){
        clearInterval(priceCheckInt)
      resolve()})
    
      }
    
    
        }
        
        
        )
      
      })
      
      }

resetprice=()=>{
//this.setState({expires:moment.utc().add(1, 'minutes').format()},

priceCheckInt=setInterval(()=>{ 
  
  
  if(!this.state.priceLocked){
  this.getPrice();} }, 15000);



//)
}


purchase=()=>{


this.setState({step : this.state.step + 1},function(){
this.getPrice().then(

this.setState({displayPrice:true})
)
})
//}}


 // )

}


checkPaymentTimer(){

  paymetCheckInt=setInterval(()=>{ 
   if(!this.state.paymentApproved){ 
this.setState({showPaynow:false,priceLocked:false,wallet_address:'',wallet_address2:''},()=>
this.setState({step:1},function(){clearInterval(paymetCheckInt)})
    )

    } }, 600000);

}



enterPayment=()=>{

  clearInterval(priceCheckInt)
this.setState({priceLocked:true},function(){
  this.checkPaymentTimer();


  var user=JSON.parse(localStorage.getItem('user'))

  var account_id=user.data.account_id

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("access-token", user.token);
var requestOptions = {
method: 'POST',
headers: myHeaders,
body: '{"ip":"'+this.state.ip+'","crypto_rate":'+this.state.rate+',"crypto_fee_amount":'+this.state.feeamount+',"crypto_final_amount":'+this.state.finalamount+',"amount":'+this.state.Receive+',"price":'+this.state.Pay+',"fiat":"'+this.state.fiatCurrency.toUpperCase()+'","coin":"'+this.state.cryptoCurrency.toUpperCase()+'","account_id":'+account_id+',"address":"'+this.state.wallet_address+'","tid":"'+this.state.tid+'"}'};

fetch(Config.API+`/api/addtransaction`, requestOptions)
.then( this.handleResponse)
.then(
()=>{

  this.nextStep();


})
})


   

}





  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
        //  console.log(data.status)
          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
          
               return 'error'
          }
        

        }
       

        return data;
    });
}
addWallet(){


  this.getPrice().then(

    this.setState({displayPrice:true},function(){
 
      this.nextStep()
      this.resetprice();
    })
    )



}



handleAgree(e) {

  document.getElementById('2').checked!==true
  ? this.setState({
    checkedAgreeError: (
       <small className="text-danger">You must verify that you own and control this wallet address.</small>
     )
       })
       : this.setState({ checkedAgreeError: null });

       this.state.checkedAgreeError!=='' && document.getElementById('2').checked===true
       ? this.setState({amountDisabled:false})
       : this.setState({amountDisabled:true})

return
}



handleWalletValidate(e) {


  document.getElementById('1').checked!==true
  ? this.setState({
    checkedError: (
       <small className="text-danger">You must verify that you own and control this wallet address.</small>
     )
       })
       : this.setState({ checkedError: null });
      
       var valid = WAValidator.validate(this.state.wallet_address, this.state.cryptoCurrency.toUpperCase());

  this.state.wallet_address === ""
  ? this.setState({
    walletError: (
       <small className="text-danger">Please enter your wallet address</small>
     )
       })
       : !valid
   ? this.setState({
    walletError: (
       <small className="text-danger">Please enter a valid  wallet address</small>
     )
       })
           :this.setState({walletError: null });




       this.state.wallet_address != this.state.wallet_address2
       ? this.setState({
         wallet2Error: (
            <small className="text-danger">Wallet address does not match</small>
          )
            })
            : this.setState({wallet2Error: null });
 
            this.state.wallet_address2!=='' &&  this.state.wallet_address !=='' &&  this.state.walletError === null && this.state.wallet2Error === null && document.getElementById('1').checked===true
? this.setState({walletDisabled:false})
: this.setState({walletDisabled:true})
       return


}




  render() {
    const {terms,refund,dailyWarning,weeklyWarning,rate,feeamount,finalamount,step,BuyNowDisabled,Pay,Receive,wallet_address,wallet_address2,wallet2Error,walletError,walletDisabled,approveDisabled,amountDisabled,cryptoCurrencyName,kyc,icomplyuuid,tid} = this.state; 
  
  
    
    return (
<div>
<div dangerouslySetInnerHTML={{__html:weeklyWarning}}/>
  <div dangerouslySetInnerHTML={{__html:dailyWarning}}/>
          {kyc

?<Button
                      fill
                      bsStyle="info"
                      type="submit"
                    onClick={this.Paynow}
                      disabled={BuyNowDisabled}
                    >
                    BUY {cryptoCurrencyName.toUpperCase()}
                    </Button>

                   :<Button
                   fill
                   bsStyle="info"
                   type="submit"
                
                   disabled="true"
                 >
                 BUY {cryptoCurrencyName.toUpperCase()}
                 </Button>
  }





{ step==1
?<Modal
dialogClassName="modal-90w" backdrop="static"
keyboard={false}
       aria-labelledby="example-modal-sizes-title-lg"
                     show={this.state.showPaynow}
                     onHide={() => this.setState({ showPaynow: false})}
                   >
                     <Modal.Header closeButton>
<Modal.Title>Buy {cryptoCurrencyName}</Modal.Title>

                     </Modal.Header>
                     <Modal.Body>
                     <div id="progressbar_holder"><h5 className="title">Enter {cryptoCurrencyName} Wallet Address</h5><ul id="progressbar"><li  className="current"></li><li></li><li></li></ul></div>
                     <Form horizontal >

         <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={4} smOffset={1}>
                        Wallet Address
                        </Col>
                        <Col sm={7}>

                          
                        <FormControl
                            type="Text"
                            name="wallet_address"
                          value={wallet_address}
                          onBlur={this.handleWalletValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.walletError}
                        </Col>
                      </FormGroup>
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={4} smOffset={1}>
                        Re Enter Wallet Address
                        </Col>
                        <Col sm={7}>
                        <FormControl
                            type="text"
                            name="wallet_address2"
                            value={wallet_address2}
                            onBlur={this.handleWalletValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.wallet2Error}
                        </Col>
                      </FormGroup>  
                      <FormGroup>
                      <Col componentClass={ControlLabel} sm={4} smOffset={1}>
                       
                        </Col>
                        <Col sm={7}>
                      <Checkbox
                        onClick={this.handleWalletValidate}
                        id="1"
                        label="I own and control this wallet address."
                      />
                      {this.state.checkedError}</Col>
                    </FormGroup>
                      </Form>

                     </Modal.Body>
                     <Modal.Footer>
<Button
  fill
  bsStyle="info"
  type="submit"
     onClick={() => this.addWallet()}
        disabled={walletDisabled}
    >Next
    </Button> 
 

  </Modal.Footer>
  </Modal>
:step==2
                    ?<Modal
                    dialogClassName="modal-90w" backdrop="static"
                           aria-labelledby="example-modal-sizes-title-lg"
                                         show={this.state.showPaynow}
                                         onHide={() => this.setState({ showPaynow: false})}
                                       >
                                         <Modal.Header closeButton>
                       <Modal.Title>Buy {cryptoCurrencyName}</Modal.Title>
                    
                                         </Modal.Header>
                                         <Modal.Body>
                                         <div id="progressbar_holder"><h5 className="title">Approve</h5><ul id="progressbar"><li  ></li><li className="current"></li><li></li></ul></div>
                                         <Form horizontal>
                           
                                          <FormGroup>
                                          <Col componentClass={ControlLabel} sm={12} >

                                          <div className="text-center"><strong>Purchase:</strong>  ${Pay} {this.state.fiatCurrency.toUpperCase()}<br/><br/>
                                          <strong>Processing Fee:</strong>  (2.99% or a minimum of $5.00 transaction fee)<br/><br/>
                                          <div className="success"><strong>Net {this.state.cryptoCurrency.toUpperCase()} Received:</strong>  {finalamount} </div><br/>
                                          <strong>Wallet:</strong> {wallet_address} 
 <br/>
                                          </div>
                                           <div className="text-center"><br/>
                                           *Your credit card may charge additional fees, if you are unsure please check with your credit card company before you complete your purchase.
 
                                            </div>

                                            </Col>
                                            <Col componentClass={ControlLabel} sm={12} >
                                          <Checkbox
                                            onClick={this.handleAgree}
                                            id="2"
                                            label="I agree on this price and all associated fees"
                                          />
                                          {this.state.checkedAgreeError}<br/><a href={terms} target="_blank">Terms of Use</a> & <a href={refund} target="_blank">Refund Policy</a></Col>
                                        </FormGroup>
                                          </Form>
                    
                                         </Modal.Body>
                                         <Modal.Footer>
                                         <Button
  fill
  bsStyle="info"
  type="submit"
     onClick={() => this.prevStep()}
        
    >Back
    </Button>
    {finalamount=='0.000000'  
    ?<Button
                      fill
                      bsStyle="info"
                      type="submit"
                        
                            disabled={true}
                        >Next
                        </Button>
                        :<Button
                        fill
                        bsStyle="info"
                        type="submit"
                           onClick={() => this.enterPayment()}
                              disabled={amountDisabled}
                          >Next
                          </Button>}
                     
                    
                      </Modal.Footer>
                      </Modal>
                                         :step==3

                                         ?<Modal
                                         dialogClassName="modal-90w" backdrop="static"
                                                aria-labelledby="example-modal-sizes-title-lg"
                                                              show={this.state.showPaynow}
                                                              onHide={() => this.setState({ showPaynow: false})}
                                                            >
                                                              <Modal.Header closeButton>
                                            <Modal.Title>Buy {cryptoCurrencyName}</Modal.Title>
                                         
                                                              </Modal.Header>
                                                              <Modal.Body>
                                                              <div id="progressbar_holder"><h5 className="title">Make Payment</h5><ul id="progressbar"><li  ></li><li></li><li className="current"></li></ul></div>
        

                                                              <div className="frame-content" id="mobilum-container">
                                                          
<Widget tid={tid} icomplyuuid={icomplyuuid} pay={Pay} />

                                                        
                                                                  
        
     
            </div>         


                                         
                                                              </Modal.Body>
                                                              <Modal.Footer>
                                                           
                                          
                                         
                                           </Modal.Footer>
                                           </Modal>


                                         :''
                                         
                                         
                                         }
                  

</div>



    )

}

    }

    export default Paynow;
    