import React, { Component } from "react";
import { Grid,   Col,Row,FormControl, FormGroup, ControlLabel,InputGroup} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Loader from "components/Loader/overlayloader.jsx";
import Paynow from "components/Paynow/Paynow.jsx";
const Config = require('config.js');


class Exchange extends Component {

constructor(props) {
  super(props);

  this.state = {
  kyc:false,
  kycStatus:'',
    BuyNowDisabled:true,
    FiatPrice:'',
    CryptoPrice:'',
    getCryptoPrice:false,
    getFiatPrice:false,
  fiatCurrency:'usd',
  cryptoCurrency:'btc',
  cryptoCurrencyName:'Bitcoin',
  cryptoIcon:'fab fa-bitcoin',
  fiatIcon:'fas fa-dollar-sign',
  showFiat:false,
  showCrypto:false,
  cryptolist:[],
  fiatlist:[],
  rate:'',
  warning:'',
  warning2:''
  }
  this.handleChangeFiatPrice = this.handleChangeFiatPrice.bind(this);
  this.handleChangeCryptoPrice = this.handleChangeCryptoPrice.bind(this);
  
}


componentDidMount () {

  this.getFiat()
  this.getCrypto()

    var user=JSON.parse(localStorage.getItem('user'));
    if (user.data.kyc=='Verified'){this.setState({kyc:true,kycStatus:user.data.kyc})}else{
      this.setState({kyc:false,kycStatus:user.data.kyc})}

    
    
  }


  getCrypto(){

    var user=JSON.parse(localStorage.getItem('user'));
    var token=user.token;
    const requestOptions = {
      headers: {'access-token': token},
   };
      
var fiat=this.state.fiatCurrency

    fetch(Config.API+'/api/getcryptoassets/'+fiat,requestOptions)
    .then( this.handleResponse)
    .then( cryptodata => this.setState({ cryptolist:cryptodata.data}));
   

  }

  getFiat(){

    var user=JSON.parse(localStorage.getItem('user'));
    var token=user.token;
    const requestOptions = {
      headers: {'access-token': token},
   };
      
var fiat=this.state.fiatCurrency

    fetch(Config.API+'/api/getfiatcurrencies',requestOptions)
    .then( this.handleResponse)
    .then( fiatdata => this.setState({ fiatlist:fiatdata.data}));
   

  }



  handleChangeCryptoPrice(e) {

   


    const { name, value } = e.target;
    this.setState({ [name]: value },function(){
      if(isNaN(this.state.FiatPrice) || /\s/.test(this.state.FiatPrice)){

        this.setState({CryptoPrice:'',FiatPrice:'',BuyNowDisabled:true})
      }else{

        this.setState({ [name]: value },function(){

          var duration = 800;
          clearTimeout(this.inputTimer);
          this.inputTimer = setTimeout(()=>{
            this.setState({getCryptoPrice:true},function(){
              this.getCryptoPrice();
            })
            
        }, duration);



    
      })}
    });
 
}

handleChangeFiatPrice(e) {
  const { name, value } = e.target;
  this.setState({ [name]: value },function(){

    if(isNaN(this.state.CryptoPrice) || /\s/.test(this.state.CryptoPrice)){
  
      this.setState({CryptoPrice:'',FiatPrice:'',BuyNowDisabled:true})
    }else{
      this.setState({ [name]: value },function(){
        var duration = 800;
        clearTimeout(this.inputTimer);
        this.inputTimer = setTimeout(()=>{
          this.setState({getFiatPrice:true},function(){
            this.getFiatPrice();
          })
      }, duration);
      
        })}
  });

}

updateFiat=(fiat,icon)=>{

  this.setState({fiatIcon:icon,fiatCurrency:fiat,showCrypto:false,showFiat:false,FiatPrice:'',CryptoPrice:'',cryptoCurrency:'btc',cryptoCurrencyName:'Bitcoin',cryptoIcon:'fab fa-bitcoin',BuyNowDisabled:true},function(){
    this.getCrypto()
  })

}

updateCrypto=(crypto,cryptoname,icon)=>{
this.setState({cryptoIcon:icon,cryptoCurrencyName:cryptoname,cryptoCurrency:crypto,showCrypto:false,showFiat:false,FiatPrice:'',CryptoPrice:'',BuyNowDisabled:true})

}

getCryptoPrice=()=>{


  var price=parseFloat(this.state.FiatPrice).toFixed(2)
  var pairing=this.state.cryptoCurrency+'_'+this.state.fiatCurrency
    return new Promise( async resolve => {
  
      //console.log(email)
      var user=JSON.parse(localStorage.getItem('user'))
          var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("access-token", user.token);
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: '{"asset":"'+pairing+'","price":'+price+'}'};
  
  fetch(Config.API+`/api/getcryptoprice`, requestOptions)
  .then( this.handleResponse)
    .then(result => {
  
  //console.log(price)
  //console.log(bidsamount[0].price)
    this.setState({CryptoPrice:result.data.crypto,rate:result.data.rate,FiatPrice:price},function(){
  
      if(this.state.CryptoPrice > 0 && this.state.FiatPrice >= 50){if(this.state.FiatPrice < 1501){
        this.setState({BuyNowDisabled:false,getCryptoPrice:false,warning:'',warning2:''},
        function(){resolve()})

      }else{ this.setState({BuyNowDisabled:true,getCryptoPrice:false,warning:'',warning2:'<div class="warning">The maximum purchase amount is for 1500.00</div><br/>'},
        function(){resolve()})}}else{ 
          
          this.setState({BuyNowDisabled:true,getCryptoPrice:false,warning:'<div class="warning">The minimum purchase amount is for 50.00</div><br/>',warning2:''},function(){resolve()})
        }
    })
    }
    
    
    )
  
  })
  
  }
  
  
  
  showFiat=()=>{ this.setState({showFiat:!this.state.showFiat})  }
  showCrypto=()=>{this.setState({ showCrypto:!this.state.showCrypto}) }

  getFiatPrice=()=>{
  
    var price=parseFloat(this.state.CryptoPrice).toFixed(6)
 
      return new Promise( async resolve => {
    
        //console.log(email)
        var user=JSON.parse(localStorage.getItem('user'))
            var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("access-token", user.token);
        var pairing=this.state.cryptoCurrency+"_"+this.state.fiatCurrency
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: '{"asset":"'+pairing+'","price":'+price+'}'};
    
    fetch(Config.API+`/api/getfiatprice`, requestOptions)
    .then( this.handleResponse)
      .then(result => { 
  
    
    //console.log(bidsamount[0].price)
      this.setState({FiatPrice:result.data.fiat,rate:result.data.rate,CryptoPrice:price},function(){
  
       
      if(this.state.CryptoPrice > 0 && this.state.FiatPrice >= 50){if(this.state.FiatPrice < 1501){
        this.setState({BuyNowDisabled:false,getFiatPrice:false,warning:'',warning2:''},
        function(){resolve()})

      }else{ this.setState({BuyNowDisabled:true,getFiatPrice:false,warning:'',warning2:'<div class="warning">The maximum purchase amount is for 1500.00</div><br/>'},
        function(){resolve()})}}else{ 
          
          this.setState({BuyNowDisabled:true,getFiatPrice:false,warning:'<div class="warning">The minimum purchase amount is for 50.00</div><br/>',warning2:''},function(){resolve()})
        }
      })
      }
      
      
      )
    
    })
    
    }
  
    handleResponse(response) {
  
      return response.text().then(text => {
          const data = text && JSON.parse(text);
   
          if (!response.ok) {
              
           // const error = (data && data.message);
            return 'error'
          }else{
          //  console.log(data.status)
            if(data.status=='error'){
          
         
              localStorage.removeItem('user');
              window.location.reload(true);
                 return 'error'}
          
  
          }
         
  
          return data;
      });
  }


  render() {
    const {cryptoIcon,fiatIcon,cryptolist,fiatlist,CryptoPrice,FiatPrice,kyc,kycStatus,BuyNowDisabled,getCryptoPrice,getFiatPrice,fiatCurrency,cryptoCurrency,cryptoCurrencyName,rate,warning,warning2} = this.state; 



    let fiatassets;  if (fiatlist) {    fiatassets = fiatlist.map(item => {      return   <li data-val={item.fiat} onClick={()=>this.updateFiat(item.fiat,item.fiat_icon)}>  <i class={item.fiat_icon}></i> {item.fiat_name}</li>;    });  }


    let cryptoassets;  if (cryptolist) {    cryptoassets = cryptolist.map(item => {      return   <li data-val={item.coin}  onClick={()=>this.updateCrypto(item.coin,item.coin_name,item.coin_icon)}>  <i class={item.coin_icon}></i> {item.coin_name}</li>;    });  }

    return (

      
      <div className="main-content buy">
   
        <Grid fluid>
          <Row >
         <Col lg={12}> {kycStatus=='NotVerified'?<Card 
      warning="true"
                  content={
                  <Row >
                    <Col  className="text-center">
                    
                    <i className="fas fa-exclamation-triangle"></i> Please <a  href="/#/verify">verify</a> your account to complete transactions. <a href="/#/verify">Verify Now</a>
    
              
                   
                    </Col>
                  
                  </Row>
                }
              />: kycStatus=='Pending'
              ?<Card 
              warning="true"
                          content={
                          <Row >
                            <Col  className="text-center">
                            
                            <i className="fas fa-exclamation-triangle"></i> Your account is still pending
</Col>
                          
                          </Row>
                        }
                      />
              
              
              : kycStatus=='Declined'
              ?<Card 
              warning="true"
                          content={
                          <Row >
                            <Col  className="text-center">
                            
                            <i className="fas fa-exclamation-triangle"></i> Your account verification is declined
</Col>
                          
                          </Row>
                        }
                      />
                    :  kycStatus=='Ineligible'
              ?<Card 
              warning="true"
                          content={
                          <Row >
                            <Col  className="text-center">
                            
                            <i className="fas fa-exclamation-triangle"></i> Your account is ineligible
</Col>
                          
                          </Row>
                        }
                      />
                      :  kycStatus=='Incomplete'
              ?<Card 
              warning="true"
                          content={
                          <Row >
                            <Col  className="text-center">
                            
                            <i className="fas fa-exclamation-triangle"></i> Your verification has been <b>flagged incomplete due to missing or unacceptable documents or information</b>. <br/>You have been emailed the details from <a href={'mailto:'+Config.supportEmail} target="_blank">{Config.supportEmail}</a>. Please reply to the ticket with required information to be verified.
</Col>
                          
                          </Row>
                        }
                      />
              :<div></div>}
              
              </Col>
        
        <Col lg={12}>
          
        <Card 

                  content={
                    <Row >
                    <Col   >
                      
               




                     
                  
                      
          <Col sm={12} md={5}>
        <FormGroup controlId="FiatPrice" bsSize="large">  
        <h4>You Spend  {BuyNowDisabled}</h4>
 
         <div className="amount-input__wrapper">
         <div className="amount-input amount-field">  <i className={fiatIcon}></i>
     { getFiatPrice===true ?<Loader /> :
     <input type="text"  className="amount-input__input"  name='FiatPrice'
          value={FiatPrice}
   
      onChange={this.handleChangeCryptoPrice}
     pattern="^[0-9]+([\.][0-9]+)?$" inputmode="decimal" placeholder="0.00" step="0.01" tabindex="1">
      </input>}
      <div className="amount-input-options">
     <button  className="amount-input-options-toggle btn-primary" type="button" onClick={this.showFiat}><span className="amount-input-options-toggle__currency ">{fiatCurrency.toUpperCase()} {this.state.showFiat?<i class="fas fa-angle-up"></i>:<i class="fas fa-angle-down"></i>}</span><span class="amount-input-options-toggle__source service-name">Credit Card</span></button>
        </div></div></div> {this.state.showFiat ?<div className="choose_currency" ><ul>
          
    {fiatassets}
        </ul></div>:<div></div>}
 
  </FormGroup>
</Col>
<Col sm={12} md={2} className="text-center exchange">                      
                       
<i class="fas fa-angle-double-right "></i>

   </Col>

<Col sm={12} md={5}>
                      <FormGroup controlId="CryptoPrice" bsSize="large">  
    
    <h4>You Receive</h4>
    

    <div className="amount-input__wrapper">
      <div className="amount-input amount-field">     <i className={cryptoIcon}></i>  { getCryptoPrice===true ?<Loader /> :
<input type="text"  className="amount-input__input" name='CryptoPrice'  onChange={this.handleChangeFiatPrice}
  value={CryptoPrice} pattern="^[0-9]+([\.][0-9]+)?$" inputmode="decimal" placeholder="0.00" step="0.01" tabindex="2">
   </input>}
   <div className="amount-input-options">
      <button  className="amount-input-options-toggle btn-primary" type="button" onClick={this.showCrypto}><span className="amount-input-options-toggle__currency ">{cryptoCurrency.toUpperCase()} {this.state.showCrypto ?<i class="fas fa-angle-up"></i>:<i class="fas fa-angle-down"></i>}</span><span class="amount-input-options-toggle__source service-name"> {cryptoCurrencyName}</span><span></span></button>
     </div></div></div>  <div></div>{this.state.showCrypto ?<div className="choose_currency" ><ul>
{cryptoassets}
 
    
   

</ul></div>:<div></div>}
  </FormGroup>
                </Col>

                <Col sm={12} ><div dangerouslySetInnerHTML={{__html:warning}}/><div dangerouslySetInnerHTML={{__html:warning2}}/>
            { !BuyNowDisabled  ?<div>1 {cryptoCurrency.toUpperCase()} = $  {rate} {fiatCurrency.toUpperCase()}<br/><br/></div>:''}
                  <Paynow BuyNowDisabled={BuyNowDisabled} kyc={kyc} CryptoName={cryptoCurrencyName} Pay={FiatPrice} CryptoCurrency={this.state.cryptoCurrency} FiatCurrency={this.state.fiatCurrency} ></Paynow>
                 
                </Col>

                      </Col> </Row>
                  } />
                     </Col>


   
          </Row>
        </Grid>


      </div>
    );
  }
}

export default Exchange;
