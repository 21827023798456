import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,

} from "react-bootstrap";

const Config = require('config');

class Pending extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
      };

 
  }

  componentWillMount(){



  }


  render() {
    const { } = this.state;
  



   
     return (
      <div className="main-content">


 <Grid fluid>
   
          <Row>
         
                    <Col md={12}> 
            
              <div><h2>Thank you for submitting your info for verification</h2><br/>
              Your account status is currently pending.<br/> Verification can take up to 24 hours. We will send you an email within 24 hrs once your account has been fully verified.<br/>
              You can also check by re-logging in.<br/>
              If you have any questions please contect <a href={'mailto:'+Config.supportEmail}>{Config.supportEmail}</a></div>
              
            </Col>
                 
                  </Row>
               
        </Grid>
      </div>
    );
  }
}

export default Pending;
