import LoginPage from "views/Pages/LoginPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import LockScreenPage from "views/Pages/LockScreenPage.jsx";
import ForgotPassword from "views/Pages/ForgotPassword.jsx";
import ResetPassword from "views/Pages/ResetPassword.jsx";
import VerifyEmail from "views/Pages/VerifyEmail.jsx";


var pagesRoutes = [
  {
    path: "/a/login-page",
    name: "Login Page",
    mini: "LP",
    component: LoginPage
  },
  {
    path: "/a/register-page",
    name: "Register",
    mini: "RP",
    component: RegisterPage
  },
  {path: "/a/forgot-password",
  name: "Forgot Password",
  mini: "LP",
  component: ForgotPassword
},
{path: "/a/password-reset/:userid/:token",
name: "Reset Password",
mini: "LP",
component: ResetPassword
},
{path: "/a/verify/:userid/:token",
name: "Verify Email",
mini: "LP",
component: VerifyEmail
},
  {
    path: "/a/lock-screen-page",
    name: "Lock Screen Page",
    mini: "LSP",
    component: LockScreenPage
  },
  
];

export default pagesRoutes;
