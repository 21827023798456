import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";
import { ExportToCsv } from 'export-to-csv';
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import 'react-table-6/react-table.css'
const Config = require('config');


class Transactions extends Component {




    constructor(props){
        super(props);


        
                this.state = {
                  loading: true,
                transactionsdatalist:[]
                  
                }
               
              //  console.log('this.props', this.props)
            }
    

            
componentWillMount(){

    this.gettransactions();

  }
  export(){ 
    const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        filename:'Transactions',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: false,
        title: '',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: ['id','date time','coin','amount','currency','final_amount','wallet address','txid','transfer status'],
        receiptPdf:''
      };
     
    const csvExporter = new ExportToCsv(options);
    var user=JSON.parse(localStorage.getItem('user'));
    var mid=user.data.merchant_id;
    var token=user.token;
    const requestOptions = {
      headers: {'access-token': token},
   };
      


    fetch(Config.API+'/api/transactions/'+mid,requestOptions)
    .then( this.handleResponse)
    .then( transactiondata => this.setState({ transactionsdatalist:transactiondata.data,loading:false,}))
    .then (csvExporter.generateCsv(this.state.transactionsdatalist));

      

  }
  gettransactions(){

    var user=JSON.parse(localStorage.getItem('user'));
    var aid=user.data.account_id;
    var token=user.token;
    const requestOptions = {
      headers: {'access-token': token},
   };
      


    fetch(Config.API+'/api/transactions/'+aid,requestOptions)
    .then( this.handleResponse)
    .then( transactiondata => this.setState({ transactionsdatalist:transactiondata.data,loading:false}));
   

  }

  getReceipt(tid){


    var user=JSON.parse(localStorage.getItem('user'));
    var aid=user.data.account_id;
 
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','access-token': user.token ,},
      body: JSON.stringify({ tid,aid})
   };
      


    fetch(Config.API+'/api/generatereceipt',requestOptions)
    .then( this.handleResponse)
    .then( receipt => this.setState({ receiptPdf:receipt.data},function(){
var content=this.state.receiptPdf
console.log(this.state.receiptPdf)

var fileName = "receipt.pdf";




//const file = new Blob([content], { type: "application/pdf" });

var fileURL = `data:application/pdf;base64,${content}`
var a = document.createElement("a");
document.body.appendChild(a);
a.href = fileURL;
a.download = fileName;
a.click();
    }));
   
      
 


  }

  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}




  

  render() {
    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
        <Grid fluid>
          <Row>
          <Col lg={12}>   <h3 className="header">Transactions</h3></Col>
<Col lg={12}>

          <div className="card">
      
             

          <div className="content">
         
 

      
                  <ReactTable
                  
                    data={this.state.transactionsdatalist}
                    filterable
                    columns={[

                      {
                        Header: "id",
                        accessor: "tid"
                      }
                      ,
                      {
                        Header: "Date",
                        accessor:"transaction_date",
                       
                        Cell: props => {
                          return <Moment format="YYYY-MM-D H:mm:ss">{props.value}</Moment>;
                        },
                      },
                     
                
                      {
                        Header: "Coin",
                        accessor: "coin"
                      }
                      ,
                      {
                        Header: "Amount",
                        accessor: "crypto_final_amount"
                      }
                      ,
                      {
                        Header: "Currency",
                        accessor: "fiat"
                      }
                      ,{
                        Header: "Price",
                        id: "final_amount",
                        accessor: d => {return d.final_amount==null
                          ? 0
                        : d.final_amount },
                        Cell: props => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.value)
                      },
                      {
                        Header: "Wallet Address",
                        accessor: "address"
                      }
                      ,
                      {
                        Header: "TXID",
                        accessor: "transaction_id",
                        
                        Cell: function(props){

                          if(props.value!=''){
if(props.original.coin=='BTC'){   return <div dangerouslySetInnerHTML={{ __html:'<a href="'+Config.BtcTxUrl+props.value+'" target="_blank">'+props.value+'</a>'}} />    ;}
else if(props.original.coin=='BCH'){   return <div dangerouslySetInnerHTML={{ __html:'<a href="'+Config.BchTxUrl+props.value+'" target="_blank">'+props.value+'</a>'}} />    ;}
else if(props.original.coin=='LTC'){   return <div dangerouslySetInnerHTML={{ __html:'<a href="'+Config.LtcTxUrl+props.value+'" target="_blank">'+props.value+'</a>'}} />    ;}
else if(props.original.coin=='ETH'){   return <div dangerouslySetInnerHTML={{ __html:'<a href="'+Config.EthTxUrl+props.value+'" target="_blank">'+props.value+'</a>'}} />    ;}else{
  return props.value
}
     
                          }else{

                            return props.value;
                          }

                        }
                         ,
                      },
                      {
                        Header: "Transfer Status",
                        accessor: "transfer_status"
                      }
                      ,
                      {
                        Header: "Receipt",
                        width: 50,
                        Cell: row => (
                          <div className="actions-right">
  
       <Button onClick={() => this.getReceipt(row.original.tid)}
    bsStyle="info"
    simple
    icon
    
    ><i class="fas fa-receipt"></i></Button>  {" "}
                          </div>
                      ),
                        resizable: true,
                        filterable: false,
                        sortable: false,
                      }
              
                     
                     
                      
                      
                    ]}
                    noDataText= "No Transactions Found"
                    defaultPageSize={10}
                    showPaginationBottom
                    showPaginationBottom={true}
                    className="-striped -highlight"
                  />
                

               
          </div>
          </div></Col>
          </Row>
        </Grid>
      </div>









    );
  }
}

export default Transactions;